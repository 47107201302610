<template>
  <div class="record page">
    <!-- 录播课-->
    <route-back/>
    <div class="record__box">
      <div class="record__box-bac">
        <div class="record__box-bac-title">录播课程</div>
        <div class="record__box-bac-content">
          <div class="over-flow hidden-scroll record__box-bac-content-cards">
            <record-card v-for="(i, k) in cardList" :key="k" :data="i" @click="hWatchRecord(i)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBack from "@/components/RouteBack";
import RecordCard from "@/components/RecordCard";
import MyTimetable from "@/components/MyTimetable";

export default {
  name: "Record",
  components: {MyTimetable, RecordCard, RouteBack},
  data(){
    // let reloadUrl;
    // if (process.env.NODE_ENV === 'development'){
    //   reloadUrl = 'http://192.168.20.19:8081/#/record'
    // }else if (process.env.NODE_ENV === 'production'){
    //   reloadUrl = 'http://39.99.242.100:1604/#/record'
    // }
    return{
      cardList: [],
      // reloadUrl,
    }
  },
  created() {
    this.gCourse()
  },
  methods:{
    async gCourse(){
      this.$pageLoadingOpen()
      this.cardList = await this.$_api('/online/adviser/dwsalecourse/showLuboLesson', 'post', {
        lessonType: 2,
      })
      this.$pageLoadingClose()
    },
    hWatchRecord(i){
      // const token = JSON.parse(localStorage.getItem('login-info')).token
      // window.WCRClassRoom.openUrlByExternal(this.reloadUrl + `?t=${token}&i=${i.courseId}`)
      this.$router.push({path: '/record-detail', query: {courseId: i.courseId}})
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin backImg($url) {
  background: url($url) no-repeat;
  background-size: 100% 100%;
}

@mixin fontsizeStyle($height,$fontsize,$font-weight: 400, $color: #FFF ) {
  height: $height;
  line-height: $height;
  font-size: $fontsize;
  font-weight: $font-weight;
  font-family: Microsoft YaHei;
  color: $color;
}

.record {
  position: relative;
  @include backImg("../assets/image/bg-zb.png");

  .record__box {
    width: 880px;
    height: 561px;
    padding-top: 73px;
    padding-left: 100px;

    .record__box-bac {
      width: 100%;
      height: 100%;
      @include backImg('../assets/image/pic_frame.png');
      position: relative;

      .record__box-bac-title {
        position: absolute;
        width: 256px;
        left: 312px;
        top: -28px;
        @include fontsizeStyle(76px, 22px, bold);
        @include backImg('../assets/image/bt_rec.png')
      }

      .record__box-bac-content {
        .record__box-bac-content-cards{
          width: 100%;
          height: 100%;
        }
        width: calc(100% - 50px);
        height: calc(100% - 92px);
        padding: 78px 35px 0;
      }
    }
  }
}
</style>
