<template>
  <div class="record-card left over-flow" @click.stop="$emit('click')">
    <!--    录播课卡片-->
    <div class="left record-card__img">
      <img :src="data.lessonImg" alt="">
      <div class="record-card__img-cover">
        <img src="../assets/image/ic_play.png" alt="">
      </div>
    </div>
    <div class="left record-card__content">
      <div>{{data.courseName}}</div>
      <div>共{{data.countTopic}}个课时</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordCard",
  props:{
    data: {
      type: Object,
      default: ()=>{}
    }
  }
}
</script>

<style lang="scss" scoped>
  .record-card{
    margin: 10px;
    width: 384px;
    height: 130px;
    cursor: pointer;
    background: url("../assets/image/bg_rec.png") no-repeat;
    .record-card__img{
      width: 160px;
      height: 90px;
      margin: 19px 10px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .record-card__img-cover{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(0, 0, 0, .3);
        position: absolute;
        top: 0;
        img{
          width: 60px;
          height: 60px;
          margin-top: 14px;
        }
      }
    }
    .record-card__content{
      width: 160px;
      height: 90px;
      margin: 19px 10px;
      &>:nth-child(1){
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #FFF;
        font-weight: bold;
        font-size: 16px;
        text-align: left;
      }
      &>:nth-child(2){
        font-size: 14px;
        color: #FFFFFF;
        text-align: left;
        margin-top: 20px;
      }
    }

  }
</style>
